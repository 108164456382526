import React from "react"

const Footer = ({ children }) => (
  
  <footer>
      2021 © Bible Before Bread
  </footer>
)

export default Footer
